import React, { lazy } from 'react';
const BookCall = lazy(() => import('./pages/BookCall'));
const CueWorkz = lazy(() => import('./pages/Workz'));
const CueClimb = lazy(() => import('./pages/CueClimb'));
const Products = lazy(() => import('./pages/Products'));
const HomePage = lazy(() => import('./pages/HomePage'));
const Portfolio = lazy(() => import('./pages/Portfolio'));
const About = lazy(() => import('./pages/About'));
const ContactUs = lazy(() => import('./pages/ContactUs'));
const routes = [
    {
        path: '/',
        element: <HomePage />,
    },
    {
        path: '/contact-us',
        element: <ContactUs />,
    },
    {
        path: '/about-us',
        element: <About />,
    },
    {
        path: '/portfolio',
        element: <Portfolio />,
    },
    {
        path: '/products',
        element: <Products />,
    },
    {
        path: '/book-a-call',
        element: <BookCall />,
    },
    {
        path: '/cueworkz',
        element: <CueWorkz />,
    },
    {
        path: '/cueclimb',
        element: <CueClimb />,
    },
];
export default routes;
